<template>
  <div>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="eccaAssignmentForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div v-for="option in dossiers" :key="option.id">
        <FormulateInput
          v-model="values.asbestos_dossier_ids"
          type="checkbox"
          name="asbestos_dossier_ids"
          :value="option.id"
          :disabled="option.disabled"
          validation="min:1,length"
          validation-name="opdracht"
        >
          <template #label="{ id }">
            <label :for="id" class="formulate-label tw-inline-flex tw-flex-wrap tw-gap-2">
              <a
                v-if="option.form_file"
                :href="option.form_file.url"
                target="_blank"
              >
                {{ option.form_file.filename }}
              </a>
              <span v-else>
                {{ option.uuid }}
              </span>

              <span v-if="parseInt(option.id) === parseInt(dossier.id)" class="tag tw-bg-success">
                Huidig
              </span>
              <span v-if="option.status_display" class="tag tw-bg-gray-500">
                {{ option.status_display }}
              </span>
              <span v-if="option.owner" class="tag tw-bg-gray-500">
                Eigenaar: {{ option.owner.display_name }}
              </span>
              <span v-if="option.updated_on" class="tag tw-bg-gray-500">
                Aangemaakt op: {{ option.updated_on | date-day }}
              </span>

              <button
                v-if="option.form_file || option.unsigned_form_file"
                type="button"
                title="Verwijder het ondertekende dossier"
                :disabled="resettingDossier[option.id]"
                class="tw-rounded tw-px-1 tw-py-0.5 tw-text-white tw-bg-danger tw-text-xs disabled:tw-bg-gray-cc"
                @click="dossierReset(option.id)"
              >
                <i
                  :class="[
                    'far',
                    resettingDossier[option.id]
                      ? 'fa-spinner-third fa-spin'
                      : 'fa-unlink'
                  ]"
                />
              </button>
            </label>
          </template>
        </FormulateInput>
      </div>

      <div class="tw-my-8 tw-pb-4 tw-border-b">
        <h2>Verantwoordelijk</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
          <FormulateInput
            type="select"
            name="office"
            label="Kantoor"
            :options="offices"
            :show-required-label="true"
            validation="required"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="select"
            name="collaborator"
            label="Makelaar"
            :options="collaborators"
            :show-required-label="true"
            validation="required"
            outer-class="tw-m-0"
          />
        </div>
      </div>

      <div class="tw-my-8 tw-pb-4 tw-border-b">
        <h2>Inspectielocatie</h2>
        <FormulateInput
          v-if="dossier.property_data"
          type="group"
          name="property_data"
          :value="dossier.property_data"
        >
          <div class="tw-my-4 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
            <FormulateInput
              type="text"
              name="street"
              label="Straat"
              placeholder="Straat"
              disabled
              :show-required-label="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="number"
              label="Huisnummer"
              placeholder="Huisnummer"
              disabled
              :show-required-label="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="box"
              label="Bus"
              placeholder="Bus"
              disabled
              :show-required-label="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="zip_code"
              label="Postcode"
              placeholder="Postcode"
              disabled
              :show-required-label="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="city"
              label="Gemeente"
              placeholder="Gemeente"
              disabled
              :show-required-label="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="parcels"
              label="Percelen"
              placeholder="Percelen"
              disabled
              :show-required-label="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="location_address"
              label="Liggingsadres"
              placeholder="Liggingsadres"
              disabled
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="extra_address_details"
              label="Extra adresdetails"
              placeholder="Extra adresdetails"
              disabled
              outer-class="tw-my-0"
            />
          </div>
        </FormulateInput>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
          <FormulateInput
            type="radio"
            name="property_type"
            label="Type vastgoed"
            :options="{
              residential: 'Residentieel',
              commercial: 'Commercieel'
            }"
            :input-class="['tw-my-1']"
            :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
            outer-class="tw-m-0"
            @input="$event === 'commercial' ? values.invoice_target = 'customer' : false"
          />
          <FormulateInput
            type="radio"
            name="invoice_target"
            label="Factureren naar"
            value="customer"
            :options="{
              customer: 'Klant',
              dewaele: 'Dewaele'
            }"
            :disabled="isPropertyCommercial"
            :input-class="['tw-my-1']"
            :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
            outer-class="tw-m-0"
            @input="changedInvoiceTarget"
          />
        </div>
      </div>

      <div class="tw-my-8 tw-pb-4 tw-border-b">
        <h2>Opdrachtgever</h2>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
          <FormulateInput
            type="select"
            name="owner"
            label="Opdrachtgever"
            :options="owners"
            :value="dossier.owner"
            :show-required-label="true"
            outer-class="tw-m-0"
            @input="handleOwnerChange"
          />
          <FormulateInput
            type="radio"
            name="customer_type"
            label="Type opdrachtgever"
            :options="{
              person: 'Persoon',
              business: 'Bedrijf'
            }"
            :input-class="['tw-my-1']"
            :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center']"
            outer-class="tw-m-0"
          />
        </div>
      </div>

      <div class="tw-my-8 tw-pb-4 tw-border-b">
        <h2>Facturatiegegevens</h2>
        <FormulateInput
          type="group"
          name="invoice_information"
        >
          <div class="tw-my-4 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
            <FormulateInput
              type="text"
              name="street"
              label="Straat"
              placeholder="Straat"
              validation="required"
              :show-required-label="true"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="number"
              label="Huisnummer"
              placeholder="Huisnummer"
              validation="required"
              :show-required-label="true"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="box"
              label="Bus"
              placeholder="Bus"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="zip_code"
              label="Postcode"
              placeholder="Postcode"
              validation="required"
              :show-required-label="true"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="city"
              label="Plaats"
              placeholder="Plaats"
              validation="required"
              :show-required-label="true"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />

            <FormulateInput
              type="text"
              name="email"
              label="E-mailadres"
              placeholder="E-mailadres"
              validation="required"
              :show-required-label="true"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="phone"
              label="Telefoonnummer"
              placeholder="Telefoonnummer"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="text"
              name="mobile"
              label="Mobiel nummer"
              placeholder="Mobiel nummer"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
            <FormulateInput
              v-show="(isPropertyCommercial && !isOwnerAPerson) || isInvoiceTargetDewaele || isOwnerABusiness"
              type="text"
              name="business_name"
              label="Firmanaam"
              placeholder="Firmanaam"
              :validation="((isPropertyCommercial && !isOwnerAPerson)  || isInvoiceTargetDewaele || isOwnerABusiness) ? 'required' : ''"
              :show-required-label="true"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
            <FormulateInput
              v-show="(isPropertyCommercial && !isOwnerAPerson)  || (isOwnerABusiness && !isInvoiceTargetDewaele)"
              type="select"
              name="business_type"
              label="Vennootschapsvorm"
              placeholder="Selecteer vennootschapsvorm"
              :options="BUSINESS_TYPES"
              :validation="(isPropertyCommercial && !isOwnerAPerson)  || (isOwnerABusiness && !isInvoiceTargetDewaele) ? 'required' : ''"
              :show-required-label="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              v-show="(isPropertyCommercial && !isOwnerAPerson)  || isInvoiceTargetDewaele  || isOwnerABusiness"
              type="text"
              name="business_vat"
              label="Btw-nummer"
              placeholder="Btw-nummer"
              :validation="((isPropertyCommercial && !isOwnerAPerson)  || isInvoiceTargetDewaele || isOwnerABusiness) ? 'required' : ''"
              :show-required-label="true"
              :disabled="isInvoiceTargetDewaele"
              outer-class="tw-my-0"
            />
          </div>
        </FormulateInput>
      </div>

      <div v-show="isPropertyCommercial" class="tw-my-8 tw-pb-4 tw-border-b">
        <h2>Bijkomende vragen</h2>

        <FormulateInput
          type="text"
          name="usable_area_per_building"
          label="Bruikbare oppervlakte van alle gebouwen > 20m² op het perceel (incl oppervlakte kelders,verdiepingen, zolders, bijgebouwen, schelven, mestkelders, enz...); liefst opgesplitst per gebouw."
          placeholder="Bruikbare oppervlakte"
          value=""
          :element-class="['tw-grid md:tw-grid-cols-2']"
        />
        <FormulateInput
          type="number"
          name="building_unit_count"
          label="Aantal wooneenheden op dit adres (Een wooneenheid bestaat minimaal uit bed/keuken of kitchenette/bad of douche)"
          placeholder="Aantal wooneenheden op dit adres"
          min="0"
          validation="bail|number|min:0"
          :element-class="['tw-grid md:tw-grid-cols-2']"
        />
        <FormulateInput
          type="text"
          name="outbuilding_description"
          label="Beschrijving van de bijgebouwen > 20m² (stallen, garage, hangar, mestkelder, enz...)"
          placeholder="Beschrijving van de bijgebouwen"
          value=""
        />
        <FormulateInput
          type="text"
          name="outbuildings_on_other_parcel"
          label="Zijn er bijgebouwen op een aanpalend perceel van dezelfde eigenaar die mee in hetasbestattest moeten verwerkt worden?"
          placeholder="Bijgebouwen"
          value=""
          :element-class="['tw-grid md:tw-grid-cols-2']"
        />
        <FormulateInput
          type="text"
          name="outbuildings_on_same_parcel"
          label="Zijn er bijgebouwen op hetzelfde perceel die apart zullen worden verkocht (vb garages) die een apart asbestattest moeten krijgen?"
          placeholder="Bijgebouwen"
          value=""
          :element-class="['tw-grid md:tw-grid-cols-2']"
        />
        <FormulateInput
          type="text"
          name="association_of_co_owners"
          label="Is er een VME?"
          placeholder="Is er een VME?"
          value=""
          :element-class="['tw-grid md:tw-grid-cols-2']"
        />
      </div>

      <div class="tw-my-8 tw-pb-4 tw-border-b">
        <div class="tw-my-4 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4">
          <FormulateInput
            v-show="isPropertyResidential"
            type="select"
            name="area_category"
            label="Bruto oppervlakte categorie"
            placeholder="Selecteer categorie"
            value=""
            :options="AREA_CATEGORIES"
            :show-required-label="true"
            :validation="isPropertyResidential ? 'required' : ''"
            outer-class="tw-m-0"
          />
          <FormulateInput
            v-show="values.area_category === 'investment_property'"
            type="number"
            name="investment_property_entity_count"
            label="Aantal entiteiten"
            placeholder="Aantal entiteiten"
            min="0"
            :show-required-label="true"
            :validation="values.area_category === 'investment_property' ? 'bail|required|number|min:0' : ''"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="number"
            name="gross_surface_area"
            label="Bruto oppervlakte"
            placeholder="Bruto oppervlakte"
            help="Opgelet: dit is niet altijd hetzelfde als de waarde op het EPC-attest!"
            value=""
            step=".01"
            min="0"
            :show-required-label="true"
            validation="bail|required|number|min:0"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="textarea"
            name="notes"
            label="Opmerkingen"
            :attrs="{rows: 3}"
            :input-class="['tw-h-24']"
            :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-2 tw-items-center']"
            outer-class="tw-m-0 tw-col-span-2"
          />
        </div>
        <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
          <FormulateInput
            type="radio"
            name="access_arrangement"
            label="Regeling voor toegang tot de inspectielocatie"
            :options="{
              contact_customer: 'Klant contacteren',
              pick_up_key_at_office: 'Sleutel ophalen op kantoor'
            }"
            validation="required"
            :show-required-label="true"
            :input-class="['tw-my-1']"
            :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-2 tw-items-center']"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="radio"
            name="owner_may_receive_certificate"
            label="Mag het asbestattest overgemaakt worden aan de klant?"
            :options="{
              true: 'Ja',
              false: 'Nee'
            }"
            value="true"
            validation="required"
            :show-required-label="true"
            :disabled="isInvoiceTargetDewaele"
            :input-class="['tw-my-1']"
            :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-2 tw-items-center']"
            outer-class="tw-m-0"
          />
        </div>
      </div>

      <FormulateErrors class="tw-text-right" />
      <div class="tw-flex tw-flex-wrap tw-justify-end tw-gap-4">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-paper-plane'
            ]"
          />
          Klaarzetten
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { successModal, questionModal, errorModal } from '@/modalMessages'
import { getPropertyOffices, getPropertyCollaborators } from '@/services/properties'
import { createAsbestosAssignment, getAsbestosDossiers, resetAsbestosDossier } from '@/services/orders'

export default {
  name: 'EccaAssignmentForm',
  props: {
    dossier: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    owners: {
      type: Array,
      required: true
    }
  },
  constants: {
    AREA_CATEGORIES: {
      garage_lt_50m2: 'Garage < 50m²',
      other_lt_50m2: 'Andere < 50m²',
      apartment_studio_loft_lt_150m2: 'Appartement, studio, loft < 150m²',
      residential_lt_150m2: 'Ander residentieel gebouw < 150m²',
      apartment_studio_loft_gt_150m2_lt_250m2: 'Appartement, studio, loft 150m² tot 250m²',
      residential_gt_150m2_lt_250m2: 'Ander residentieel gebouw 150m² tot 250m²',
      residential_gt_250m2_lt_500m2: 'Residentieel gebouw 250m² tot 500m²',
      residential_gt_500m2_lt_750m2: 'Residentieel gebouw 500m² tot 750m²',
      residential_gt_750m2: 'Residentieel gebouw > 750m²',
      investment_property: 'Opbrengsteigendom',
      non_residential: 'Niet-residentieel gebouw'
    },
    BUSINESS_TYPES: {
      bv: 'bv',
      nv: 'nv',
      cv: 'cv',
      vof: 'vof',
      'comm.v': 'comm.v',
      maatschap: 'maatschap',
      bvba: 'bvba',
      cvba: 'cvba',
      'comm.va': 'comm.va',
      cvoa: 'cvoa',
      'e-bvba': 'e-bvba',
      's-bvba': 's-bvba',
      'stille vennootschap': 'stille vennootschap',
      'tijdelijke vennootschap': 'tijdelijke vennootschap',
      lv: 'lv',
      esv: 'esv',
      vzw: 'vzw',
      'natuurlijke persoon': 'natuurlijke persoon'
    }
  },
  data () {
    return {
      values: {},
      dossiers: [],
      offices: [],
      collaborators: [],
      resettingDossier: {}
    }
  },
  computed: {
    isPropertyResidential () {
      return this.values.property_type === 'residential'
    },
    isPropertyCommercial () {
      return this.values.property_type === 'commercial'
    },
    isInvoiceTargetDewaele () {
      return this.values.invoice_target === 'dewaele'
    },
    isOwnerAPerson () {
      return this.values.customer_type === 'person'
    },
    isOwnerABusiness () {
      return this.values.customer_type === 'business'
    }
  },
  created () {
    this.init()
  },
  methods: {
    changedInvoiceTarget (value) {
      const owner = this.owners.find(owner => parseInt(owner.value) === parseInt(this.values.owner))
      const { company = {}, phone = '' } = this.offices.find(office => parseInt(office.value) === parseInt(this.values.office))

      let invoice_information = {}

      if (value === 'dewaele') {
        this.$set(this.values, 'owner_may_receive_certificate', 'false')

        invoice_information = {
          phone,
          mobile: '',
          street: company?.street,
          number: company?.number,
          box: company?.box,
          zip_code: company?.city?.zip_code,
          city: company?.city?.name,
          email: 'leveranciersboekhouding@dewaele.com',
          business_name: company?.name || '',
          business_type: owner?.company_type || '',
          business_vat: company?.vat || ''
        }
      } else {
        this.$set(this.values, 'owner_may_receive_certificate', 'true')
        invoice_information = this.changeInvoiceInformationForOwner()
      }
      this.$set(this.values, 'invoice_information', [invoice_information])
    },
    changeInvoiceInformationForOwner () {
      if (this.values.invoice_target === 'dewaele') return null
      const owner = this.owners.find(owner => parseInt(owner.value) === parseInt(this.values.owner))
      const invoice_information = {
        street: owner?.street,
        number: owner?.number,
        box: owner?.box,
        zip_code: owner?.zip_code,
        city: owner?.city,
        email: owner?.email,
        phone: owner?.phone,
        mobile: owner?.mobile,
        business_name: owner?.company_name || '',
        business_type: owner?.company_type || '',
        business_vat: owner?.vat || ''
      }
      return invoice_information
    },
    handleOwnerChange () {
      const invoice_information = this.changeInvoiceInformationForOwner()
      // If invoice target is dewaele, the invoice information should not be changed when the selected owner changes.
      if (invoice_information) this.$set(this.values, 'invoice_information', [invoice_information])
      this.setCustomerType()
    },
    setCustomerType () {
      const owner = this.owners.find(owner => parseInt(owner.value) === parseInt(this.values.owner))
      this.$set(this.values, 'customer_type', owner?.type === 'P' ? 'person' : 'business')
    },
    async init () {
      const propertyId = this.property.id
      this.$set(this.values, 'property_type', this.property.niche.id === 1 ? 'residential' : 'commercial')
      return await Promise.all([
        this.fetchOffices(propertyId),
        this.fetchCollaborators(propertyId),
        this.fetchAsbestosDossiers(propertyId)
      ])
    },
    async fetchOffices (propertyId) {
      const response = await getPropertyOffices(propertyId)
      const offices = response.data?.results || []

      this.offices = offices.map(office => {
        if (office.primary) this.$set(this.values, 'office', office.id)

        return {
          value: office.id,
          label: `${office.reference} ${office.name}`,
          ...office
        }
      })

      return response
    },
    async fetchCollaborators (propertyId) {
      const payload = {
        propertyId,
        params: {
          include_inactive: 1
        }
      }
      const response = await getPropertyCollaborators(payload)
      const collaborators = response.data?.results || []
      this.collaborators = collaborators.map(collaborator => {
        if (collaborator.primary) this.$set(this.values, 'collaborator', collaborator.id)

        return {
          value: collaborator.id,
          label: collaborator.display_name
        }
      })
      return response
    },
    async fetchAsbestosDossiers (property_id) {
      const response = await getAsbestosDossiers({ property_id })

      const dossiers = response.data?.results || []
      this.dossiers = dossiers?.map(
        ({ status, ...dossier }) => {
          return {
            status,
            disabled: status !== 'ready_to_send',
            ...dossier
          }
        }
      )

      this.changedInvoiceTarget('customer') // By default, it's customer
      this.$set(this.values, 'asbestos_dossier_ids', [this.dossier.id])
      this.setCustomerType()
      return response
    },
    async submit (data) {
      try {
        // No need to send property_data
        const { invoice_information, property_data, owner_may_receive_certificate, ...values } = data
        const payload = {
          property: this.property.id,
          invoice_information: invoice_information[0],
          owner_may_receive_certificate: owner_may_receive_certificate === 'true',
          ...values
        }
        const response = await createAsbestosAssignment(payload)
        this.$emit('assignment-created', response.data)
        successModal('Opdracht succesvol aangemaakt.')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'eccaAssignmentForm')
      }
    },
    async dossierReset (dossierId) {
      try {
        const result = await questionModal('Door deze handeling wordt het ondertekende document uit het dossier verwijderd.')
        if (!result.value) return

        this.$set(this.resettingDossier, dossierId, true)
        const response = await resetAsbestosDossier(dossierId)
        // We need to make the state false before fetching the data again, else we run into issues.
        this.$set(this.resettingDossier, dossierId, false)

        // If the current dossier was removed, then we should reset the user to first step.
        // If another dossier was removed, we fetch the list of dossiers again.
        if (parseInt(dossierId) === parseInt(this.dossier.id)) this.$emit('reset-current-dossier')
        else await this.fetchAsbestosDossiers(this.property.id)

        return response
      } catch (error) {
        this.$set(this.resettingDossier, dossierId, false)
        errorModal('Kan het document niet verwijderen, probeer het opnieuw.')
        throw error
      }
    }
  }
}
</script>`

<style scoped>
.tag {
  @apply tw-px-1 tw-py-0.5 tw-text-white tw-rounded tw-font-normal;
}
</style>
